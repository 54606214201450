<template>
    <template v-if="isMounted">
        <slot/>
    </template>
    <template v-else>
        <slot name="fallback"/>
    </template>
</template>

<script setup>
import {ref, onMounted, nextTick} from 'vue'
import useIsClient from "@/Composeables/useIsClient.js";

const isMounted = ref(false)
onMounted(() => {
    isMounted.value = true
})
</script>
